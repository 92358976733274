export const VALID_GUESSES = [
  'estoy',
  'tengo',
  'vamos',
  'puedo',
  'tiene',
  'ahora',
  'bueno',
  'todos',
  'puede',
  'hacer',
  'nunca',
  'sabes',
  'hasta',
  'sobre',
  'usted',
  'mejor',
  'mucho',
  'estar',
  'donde',
  'antes',
  'decir',
  'estas',
  'nadie',
  'desde',
  'favor',
  'gente',
  'cosas',
  'hecho',
  'tener',
  'quien',
  'buena',
  'ellos',
  'fuera',
  'crees',
  'todas',
  'menos',
  'haber',
  'padre',
  'claro',
  'gusta',
  'lugar',
  'luego',
  'hemos',
  'estos',
  'mismo',
  'noche',
  'mundo',
  'visto',
  'entre',
  'somos',
  'parte',
  'saber',
  'debes',
  'amigo',
  'hacia',
  'haces',
  'madre',
  'veces',
  'nuevo',
  'tanto',
  'salir',
  'mujer',
  'nueva',
  'forma',
  'sigue',
  'poder',
  'chica',
  'dejar',
  'dicho',
  'viene',
  'tomar',
  'llama',
  'habla',
  'otros',
  'justo',
  'chico',
  'cinco',
  'dicen',
  'pasar',
  'viejo',
  'pueda',
  'tarde',
  'saben',
  'venga',
  'cerca',
  'manos',
  'queda',
  'feliz',
  'igual',
  'venir',
  'clase',
  'trata',
  'hagas',
  'dices',
  'hacen',
  'matar',
  'tenga',
  'mucha',
  'creer',
  'miedo',
  'medio',
  'deben',
  'punto',
  'vivir',
  'misma',
  'lleva',
  'listo',
  'mayor',
  'ayuda',
  'horas',
  'juego',
  'llamo',
  'digas',
  'falta',
  'acaba',
  'otras',
  'comer',
  'final',
  'vemos',
  'jugar',
  'poner',
  'suena',
  'serio',
  'miren',
  'acabo',
  'viste',
  'resto',
  'culpa',
  'morir',
  'joven',
  'basta',
  'trato',
  'hijos',
  'armas',
  'perro',
  'lista',
  'lejos',
  'darle',
  'meses',
  'gusto',
  'pobre',
  'llevo',
  'estan',
  'traje',
  'grupo',
  'verte',
  'veras',
  'orden',
  'mitad',
  'hablo',
  'libro',
  'abajo',
  'campo',
  'busca',
  'calle',
  'junto',
  'pagar',
  'quise',
  'viaje',
  'tipos',
  'creen',
  'entra',
  'media',
  'fuego',
  'largo',
  'siete',
  'ganar',
  'james',
  'sitio',
  'llega',
  'dolor',
  'coche',
  'hazlo',
  'sacar',
  'cielo',
  'dejes',
  'hagan',
  'ambos',
  'lindo',
  'vayan',
  'acaso',
  'deseo',
  'mente',
  'libre',
  'tenia',
  'santo',
  'fotos',
  'hayas',
  'darme',
  'darte',
  'aquel',
  'amiga',
  'damas',
  'nivel',
  'negro',
  'tonto',
  'causa',
  'vieja',
  'linda',
  'salga',
  'corre',
  'abrir',
  'miles',
  'tanta',
  'barco',
  'carne',
  'traer',
  'salvo',
  'lleno',
  'vayas',
  'dejen',
  'vuelo',
  'verlo',
  'pedir',
  'santa',
  'pelea',
  'hotel',
  'dando',
  'honor',
  'capaz',
  'mirar',
  'ponte',
  'dulce',
  'carta',
  'ellas',
  'vista',
  'dejas',
  'luces',
  'novia',
  'rayos',
  'error',
  'tocar',
  'banco',
  'oigan',
  'doble',
  'cargo',
  'papel',
  'corte',
  'llame',
  'ponga',
  'calma',
  'ganas',
  'duele',
  'vengo',
  'perra',
  'llave',
  'norte',
  'robot',
  'quiso',
  'color',
  'novio',
  'parar',
  'sirve',
  'llena',
  'trajo',
  'reina',
  'abran',
  'volar',
  'baile',
  'pesar',
  'golpe',
  'hayan',
  'bomba',
  'larga',
  'sigan',
  'subir',
  'diste',
  'bolsa',
  'vidas',
  'bajar',
  'hable',
  'lleve',
  'irnos',
  'nueve',
  'brazo',
  'huele',
  'radio',
  'valor',
  'mando',
  'desea',
  'quede',
  'malas',
  'busco',
  'fondo',
  'tomas',
  'muere',
  'echar',
  'sabia',
  'seria',
  'beber',
  'cenar',
  'suelo',
  'bella',
  'pocos',
  'habia',
  'diles',
  'mueve',
  'banda',
  'quedo',
  'yendo',
  'robar',
  'pones',
  'hazme',
  'hogar',
  'pista',
  'llego',
  'lucha',
  'toque',
  'broma',
  'tomen',
  'carga',
  'pongo',
  'coger',
  'calor',
  'vives',
  'marca',
  'verme',
  'cuida',
  'vimos',
  'costa',
  'serie',
  'leyes',
  'reloj',
  'torre',
  'mires',
  'dejan',
  'fuese',
  'viven',
  'andar',
  'siglo',
  'ponen',
  'cinta',
  'socio',
  'seres',
  'crear',
  'prisa',
  'verla',
  'gordo',
  'virus',
  'pasan',
  'quita',
  'caray',
  'muero',
  'canal',
  'verde',
  'deber',
  'salud',
  'meter',
  'menor',
  'sepas',
  'pulso',
  'primo',
  'corta',
  'silla',
  'joder',
  'pocas',
  'hielo',
  'casas',
  'leche',
  'balas',
  'video',
  'total',
  'truco',
  'datos',
  'carro',
  'traes',
  'oeste',
  'dedos',
  'pense',
  'flota',
  'firma',
  'reino',
  'naves',
  'adoro',
  'regla',
  'juega',
  'curso',
  'salen',
  'apoyo',
  'malos',
  'nariz',
  'ayude',
  'corto',
  'clave',
  'ambas',
  'tirar',
  'ideas',
  'metas',
  'modos',
  'mover',
  'fecha',
  'digan',
  'creas',
  'guste',
  'tomes',
  'droga',
  'ruido',
  'tumba',
  'damos',
  'sales',
  'miras',
  'pared',
  'manda',
  'trate',
  'salgo',
  'turno',
  'magia',
  'trago',
  'pasos',
  'copia',
  'casos',
  'grave',
  'conde',
  'autos',
  'pagan',
  'firme',
  'cable',
  'fumar',
  'solos',
  'harto',
  'rumbo',
  'usado',
  'hecha',
  'playa',
  'cerdo',
  'apaga',
  'polvo',
  'paseo',
  'nieve',
  'locos',
  'pollo',
  'motor',
  'bajen',
  'actor',
  'pasas',
  'cajas',
  'disco',
  'tarea',
  'ruego',
  'junta',
  'negra',
  'bravo',
  'sucio',
  'plata',
  'techo',
  'pecho',
  'queso',
  'logro',
  'genio',
  'ponlo',
  'museo',
  'minas',
  'acabe',
  'saque',
  'noble',
  'clima',
  'bruja',
  'notas',
  'lunes',
  'amaba',
  'punta',
  'sabor',
  'local',
  'plato',
  'pieza',
  'china',
  'vivos',
  'ritmo',
  'almas',
  'trozo',
  'placa',
  'toman',
  'salsa',
  'vende',
  'papas',
  'falla',
  'salve',
  'paren',
  'dimos',
  'matan',
  'bordo',
  'pasen',
  'julio',
  'crema',
  'salva',
  'arena',
  'extra',
  'caras',
  'tonta',
  'celda',
  'aviso',
  'obvio',
  'bolas',
  'ratas',
  'maten',
  'sonar',
  'rompe',
  'ricos',
  'canta',
  'suban',
  'humor',
  'putas',
  'globo',
  'suave',
  'comen',
  'denme',
  'darse',
  'sepan',
  'traen',
  'texas',
  'venta',
  'paris',
  'bello',
  'vuela',
  'borde',
  'danos',
  'rueda',
  'ciego',
  'entro',
  'lados',
  'guapo',
  'metro',
  'monte',
  'gatos',
  'botas',
  'mirad',
  'peces',
  'suele',
  'sacas',
  'dinos',
  'reyes',
  'burro',
  'tetas',
  'hazte',
  'salta',
  'mueva',
  'deuda',
  'odias',
  'lanza',
  'huevo',
  'bolso',
  'andas',
  'metes',
  'elige',
  'cruel',
  'legal',
  'calla',
  'rusos',
  'pases',
  'cobra',
  'salto',
  'casar',
  'acero',
  'verle',
  'temas',
  'muera',
  'diera',
  'chino',
  'pinta',
  'marco',
  'comes',
  'artes',
  'pizza',
  'quite',
  'denle',
  'cazar',
  'letra',
  'lavar',
  'dosis',
  'vegas',
  'matas',
  'plaza',
  'nadar',
  'lento',
  'fines',
  'Corea',
  'rubia',
  'razon',
  'unica',
  'niega',
  'grado',
  'valle',
  'kilos',
  'dudas',
  'tigre',
  'ducha',
  'aldea',
  'obras',
  'cubre',
  'casco',
  'amado',
  'guapa',
  'besar',
  'prima',
  'sigas',
  'metal',
  'ayudo',
  'solas',
  'abril',
  'falsa',
  'temes',
  'podia',
  'junio',
  'aguas',
  'opina',
  'digno',
  'bajas',
  'debas',
  'patio',
  'pague',
  'demos',
  'delta',
  'rollo',
  'plena',
  'fallo',
  'ataca',
  'barra',
  'villa',
  'huelo',
  'andan',
  'estes',
  'viera',
  'rango',
  'falso',
  'usaba',
  'furia',
  'frase',
  'mande',
  'gorda',
  'vodka',
  'odian',
  'niego',
  'cuide',
  'preso',
  'posee',
  'piano',
  'pisos',
  'marzo',
  'hazle',
  'altas',
  'lucir',
  'lance',
  'atras',
  'sabio',
  'pegar',
  'mafia',
  'sable',
  'pelos',
  'parto',
  'autor',
  'votos',
  'mates',
  'cruza',
  'flujo',
  'depto',
  'temen',
  'norma',
  'serlo',
  'ojala',
  'durar',
  'tiros',
  'quito',
  'metan',
  'abuso',
  'presa',
  'danza',
  'atado',
  'sacan',
  'pacto',
  'gafas',
  'enero',
  'trono',
  'fruta',
  'crece',
  'bases',
  'negar',
  'canto',
  'tiren',
  'tarda',
  'nubes',
  'abras',
  'daban',
  'suene',
  'queja',
  'halla',
  'vivas',
  'unido',
  'raras',
  'indio',
  'girar',
  'bebes',
  'sobra',
  'coman',
  'acoso',
  'tenis',
  'quiza',
  'murio',
  'haria',
  'culpo',
  'ciega',
  'tabla',
  'jodas',
  'jamas',
  'caleb',
  'oreja',
  'grito',
  'macho',
  'duros',
  'abres',
  'sirva',
  'llora',
  'judas',
  'exige',
  'susto',
  'feria',
  'camas',
  'times',
  'subes',
  'licor',
  'vital',
  'trece',
  'monos',
  'eches',
  'selva',
  'exijo',
  'bodas',
  'pluma',
  'nieto',
  'drama',
  'comas',
  'choca',
  'abren',
  'cazas',
  'jodan',
  'roban',
  'hadas',
  'caigo',
  'armar',
  'renta',
  'plaga',
  'copas',
  'rodar',
  'etapa',
  'duelo',
  'cagar',
  'tarta',
  'rojas',
  'gases',
  'fatal',
  'boxeo',
  'tumor',
  'echen',
  'decia',
  'barba',
  'diana',
  'bicho',
  'paras',
  'cavar',
  'amada',
  'gotas',
  'celos',
  'mesas',
  'esten',
  'veloz',
  'nazis',
  'lgual',
  'tuyos',
  'orina',
  'arroz',
  'mapas',
  'seran',
  'sonda',
  'duras',
  'zonas',
  'valga',
  'tubos',
  'viuda',
  'dejad',
  'aleja',
  'locas',
  'falda',
  'duque',
  'venda',
  'sordo',
  'seras',
  'pleno',
  'pesca',
  'cuido',
  'tropa',
  'labor',
  'cobro',
  'temer',
  'milla',
  'gasto',
  'suyos',
  'dadas',
  'asilo',
  'vales',
  'sopla',
  'roman',
  'orcos',
  'muros',
  'bonos',
  'pasto',
  'duran',
  'voten',
  'salte',
  'filas',
  'corra',
  'palos',
  'horno',
  'vinos',
  'rompo',
  'rojos',
  'peste',
  'finge',
  'elijo',
  'cause',
  'botes',
  'angel',
  'torta',
  'tokio',
  'cuyos',
  'chupa',
  'tires',
  'texto',
  'porno',
  'pidas',
  'oigas',
  'nacer',
  'dicha',
  'opino',
  'echan',
  'culto',
  'abeja',
  'rubio',
  'alias',
  'tuyas',
  'solar',
  'rival',
  'lucen',
  'duane',
  'cofre',
  'apoya',
  'anual',
  'ruega',
  'polis',
  'ciclo',
  'yegua',
  'pidan',
  'gasta',
  'tiran',
  'peleo',
  'nigel',
  'fibra',
  'coges',
  'burla',
  'rabia',
  'latas',
  'haras',
  'bajan',
  'mosca',
  'logre',
  'ilama',
  'dados',
  'torpe',
  'plazo',
  'fumas',
  'ficha',
  'vasos',
  'doler',
  'buque',
  'avion',
  'zorro',
  'rompa',
  'queen',
  'poeta',
  'litro',
  'besos',
  'tenes',
  'multa',
  'manta',
  'luzco',
  'romeo',
  'llene',
  'golfo',
  'bingo',
  'serte',
  'lucio',
  'curva',
  'reese',
  'recto',
  'penas',
  'ocupe',
  'mudar',
  'email',
  'calvo',
  'torno',
  'medir',
  'enojo',
  'cifra',
  'jurar',
  'honra',
  'venia',
  'tango',
  'surge',
  'salas',
  'desee',
  'votar',
  'usada',
  'raros',
  'pizca',
  'pegas',
  'libra',
  'lazos',
  'ebrio',
  'debra',
  'bares',
  'bajes',
  'acusa',
  'suben',
  'puras',
  'odiar',
  'nacen',
  'dudar',
  'cabra',
  'avise',
  'marta',
  'marea',
  'freno',
  'besas',
  'traed',
  'robos',
  'perdi',
  'pelee',
  'parta',
  'naval',
  'lucho',
  'hacha',
  'suero',
  'lloro',
  'guiar',
  'euros',
  'bajos',
  'vapor',
  'sesos',
  'filme',
  'acera',
  'viajo',
  'senos',
  'justa',
  'solia',
  'cosmo',
  'robas',
  'marte',
  'jeque',
  'evita',
  'venas',
  'linux',
  'cesar',
  'aroma',
  'valla',
  'pavos',
  'missy',
  'corro',
  'bailo',
  'atajo',
  'atada',
  'asado',
  'serle',
  'salid',
  'rutas',
  'pozos',
  'pesan',
  'oiste',
  'marge',
  'tened',
  'roble',
  'lunar',
  'lenta',
  'lecho',
  'jaque',
  'bruto',
  'brisa',
  'aires',
  'sudor',
  'segun',
  'ramas',
  'paran',
  'omura',
  'ringo',
  'opera',
  'hawai',
  'halle',
  'haced',
  'gripe',
  'creia',
  'bilbo',
  'vuele',
  'genes',
  'diria',
  'devon',
  'damon',
  'barro',
  'arder',
  'allan',
  'subas',
  'qaeda',
  'notar',
  'creed',
  'amber',
  'abrid',
  'redes',
  'nobel',
  'grite',
  'elfos',
  'dadme',
  'cuota',
  'culos',
  'porta',
  'pesos',
  'pagos',
  'ninja',
  'madam',
  'gorra',
  'beben',
  'vivan',
  'trama',
  'hondo',
  'valer',
  'puros',
  'fruto',
  'firmo',
  'capas',
  'bolos',
  'apuro',
  'ultra',
  'podes',
  'pegue',
  'mares',
  'duela',
  'denos',
  'sexta',
  'frito',
  'anota',
  'rodea',
  'nudos',
  'eleva',
  'coged',
  'taxis',
  'pidio',
  'panza',
  'monta',
  'hayes',
  'fijar',
  'farsa',
  'bebas',
  'apodo',
  'suyas',
  'marla',
  'feroz',
  'elija',
  'anima',
  'sanos',
  'mongo',
  'estàs',
  'enoja',
  'dagas',
  'cesta',
  'cedar',
  'borra',
  'viena',
  'suiza',
  'senti',
  'galos',
  'cojan',
  'clavo',
  'rindo',
  'plomo',
  'jarra',
  'beban',
  'poste',
  'podra',
  'muevo',
  'debia',
  'botar',
  'blusa',
  'usara',
  'tenso',
  'tacto',
  'manga',
  'locus',
  'haleh',
  'garra',
  'flaco',
  'crack',
  'blues',
  'sushi',
  'paces',
  'hazla',
  'cogen',
  'babor',
  'tesis',
  'banca',
  'anden',
  'verga',
  'vence',
  'ruina',
  'pisar',
  'pausa',
  'hoyos',
  'gorro',
  'curas',
  'carpa',
  'cansa',
  'audio',
  'viola',
  'trapo',
  'royal',
  'rotos',
  'rayas',
  'podre',
  'oveja',
  'micro',
  'lider',
  'arbol',
  'tomad',
  'sacos',
  'magos',
  'coser',
  'chivo',
  'altar',
  'risas',
  'ricas',
  'pinto',
  'haran',
  'hallo',
  'dpto.',
  'ceder',
  'antro',
  'usual',
  'sres.',
  'rogar',
  'donar',
  'cuyas',
  'cenas',
  'bledo',
  'quero',
  'patea',
  'masas',
  'himno',
  'unida',
  'tampa',
  'sufro',
  'simio',
  'secta',
  'ramos',
  'patos',
  'nieta',
  'muslo',
  'jodes',
  'huyen',
  'bulto',
  'aleta',
  'aleje',
  'union',
  'toros',
  'tibia',
  'tazas',
  'sueco',
  'panda',
  'nazca',
  'fijas',
  'debio',
  'cobre',
  'sella',
  'fusil',
  'cubro',
  'cases',
  'rampa',
  'gramo',
  'balde',
  'valgo',
  'spray',
  'soplo',
  'recta',
  'racha',
  'plana',
  'omega',
  'lunas',
  'lamer',
  'chile',
  'bando',
  'andes',
  'tarro',
  'senda',
  'motos',
  'manto',
  'maese',
  'juras',
  'huyes',
  'frena',
  'evite',
  'chulo',
  'sudar',
  'polla',
  'parce',
  'ligar',
  'graba',
  'deban',
  'cabos',
  'aorta',
  'ancho',
  'sutil',
  'semen',
  'modus',
  'fluye',
  'feliz',
  'crudo',
  'causo',
  'casan',
  'cante',
  'calme',
  'brote',
  'agudo',
  'zurdo',
  'tutor',
  'tonos',
  'rezas',
  'rejas',
  'queme',
  'natal',
  'migas',
  'gozar',
  'razas',
  'ligas',
  'hedor',
  'edema',
  'digna',
  'bates',
  'audaz',
  'traga',
  'tardo',
  'tapar',
  'salon',
  'pilas',
  'pesas',
  'pegan',
  'opone',
  'olido',
  'hurra',
  'hongo',
  'grata',
  'daros',
  'osito',
  'luche',
  'huela',
  'finca',
  'ficus',
  'vagar',
  'teman',
  'potro',
  'monto',
  'labio',
  'Jorge',
  'ganes',
  'dique',
  'cerda',
  'celia',
  'caros',
  'poseo',
  'moros',
  'goteo',
  'gallo',
  'emite',
  'dabas',
  'cubos',
  'vinci',
  'veran',
  'rodeo',
  'jeans',
  'huido',
  'falte',
  'citar',
  'choza',
  'caben',
  'Bronx',
  'batir',
  'sabes',
  'pedos',
  'pateo',
  'monja',
  'lapso',
  'juste',
  'fluya',
  'denso',
  'cejas',
  'actua',
  'vicio',
  'sorda',
  'secos',
  'pasea',
  'fuere',
  'cubra',
  'chips',
  'basan',
  'veros',
  'trigo',
  'tinto',
  'talon',
  'rotas',
  'roben',
  'parad',
  'mocos',
  'idear',
  'huyan',
  'hanzo',
  'funda',
  'exito',
  'culpe',
  'craso',
  'vente',
  'vasta',
  'vagos',
  'rogue',
  'renal',
  'ranas',
  'poned',
  'pillo',
  'peine',
  'hobby',
  'ganen',
  'falle',
  'dònde',
  'barca',
  'vello',
  'tramo',
  'pulpo',
  'posar',
  'pinza',
  'penes',
  'onzas',
  'mitos',
  'menta',
  'mateo',
  'llore',
  'gruta',
  'fumen',
  'chapa',
  'callo',
  'buceo',
  'brujo',
  'asuma',
  'adama',
  'pudra',
  'poker',
  'odies',
  'mojar',
  'mango',
  'juzga',
  'feiiz',
  'cruzo',
  'colas',
  'cerro',
  'canso',
  'cagan',
  'asume',
  'armes',
  'traba',
  'terco',
  'tensa',
  'tacos',
  'sumas',
  'sueno',
  'prime',
  'persa',
  'lanzo',
  'gajes',
  'finjo',
  'Cairo',
  'bocas',
  'algas',
  'agita',
  'turco',
  'tenlo',
  'secas',
  'quejo',
  'pedal',
  'orine',
  'mutuo',
  'letal',
  'jalar',
  'hippy',
  'crash',
  'ahoga',
  'quemo',
  'prior',
  'pisas',
  'ozono',
  'hilos',
  'ganso',
  'epoca',
  'coral',
  'cojas',
  'alfil',
  'acuso',
  'vasto',
  'trepa',
  'sumar',
  'sufra',
  'subid',
  'sacad',
  'Praga',
  'porte',
  'polar',
  'ogros',
  'noten',
  'malta',
  'Maine',
  'fango',
  'falto',
  'curry',
  'cetro',
  'cesto',
  'cazan',
  'balsa',
  'avda.',
  'zanja',
  'Siria',
  'runas',
  'remen',
  'picos',
  'opuso',
  'necio',
  'mecha',
  'lagos',
  'joven',
  'garza',
  'cunda',
  'anote',
  'vigor',
  'tunel',
  'torna',
  'ritos',
  'penal',
  'hunde',
  'giran',
  'finos',
  'erais',
  'serio',
  'Congo',
  'colmo',
  'apena',
  'alcen',
  'secar',
  'sanar',
  'rinde',
  'ocaso',
  'molde',
  'lente',
  'jalea',
  'grama',
  'goles',
  'flojo',
  'coste',
  'chupo',
  'barin',
  'atroz',
  'apago',
  'turbo',
  'porra',
  'paine',
  'monde',
  'lobby',
  'gaste',
  'ganga',
  'formo',
  'dance',
  'clubs',
  'capto',
  'agota',
  'zombi',
  'vaina',
  'quepa',
  'patee',
  'juran',
  'guiso',
  'flote',
  'elite',
  'dudes',
  'doren',
  'dicta',
  'deten',
  'copio',
  'chupe',
  'chili',
  'calmo',
  'ardor',
  'arden',
  'abono',
  'viese',
  'torso',
  'sodio',
  'socia',
  'regar',
  'picar',
  'ojiva',
  'korea',
  'jerga',
  'enoje',
  'cmdte',
  'avena',
  'ahogo',
  'yarda',
  'velar',
  'rudos',
  'rapto',
  'pinos',
  'osado',
  'nenas',
  'nacio',
  'joden',
  'huyas',
  'honda',
  'helio',
  'giras',
  'fumes',
  'fuman',
  'forme',
  'fiera',
  'estad',
  'colon',
  'click',
  'clava',
  'ceros',
  'arcos',
  'tenme',
  'soles',
  'saudi',
  'saldo',
  'resta',
  'recen',
  'pilla',
  'pelis',
  'ollas',
  'oirme',
  'oasis',
  'nieva',
  'nidos',
  'ladra',
  'jalas',
  'icono',
  'conte',
  'barre',
  'ataco',
  'anexo',
  'aloja',
  'vocal',
  'verso',
  'tibio',
  'talco',
  'pitos',
  'mugre',
  'momia',
  'lavas',
  'lavan',
  'juzgo',
  'ibais',
  'folla',
  'finja',
  'envia',
  'caven',
  'caldo',
  'aries',
  'anula',
  'rigor',
  'porro',
  'pasad',
  'ocean',
  'jueza',
  'horca',
  'hades',
  'fresa',
  'fobia',
  'duren',
  'diras',
  'darlo',
  'comun',
  'cinto',
  'canse',
  'busto',
  'besan',
  'ataja',
  'astro',
  'visor',
  'unete',
  'telas',
  'prado',
  'polos',
  'pogue',
  'nomas',
  'mozos',
  'miden',
  'marga',
  'mareo',
  'males',
  'liras',
  'liado',
  'imita',
  'huida',
  'decid',
  'cesen',
  'calza',
  'cague',
  'borre',
  'beses',
  'becas',
  'bambi',
  'bajad',
  'apoye',
  'alega',
  'acuda',
  'yacen',
  'volvi',
  'treta',
  'tokyo',
  'tenor',
  'talle',
  'salia',
  'sabra',
  'rezan',
  'queyo',
  'queno',
  'naden',
  'luzca',
  'laser',
  'giros',
  'fuero',
  'forro',
  'fargo',
  'cruda',
  'conto',
  'censo',
  'carpe',
  'cagas',
  'buzos',
  'bucho',
  'batea',
  'ateos',
  'alzar',
  'alpes',
  'allah',
  'zebra',
  'veria',
  'vasco',
  'traza',
  'toser',
  'tests',
  'tejas',
  'tallo',
  'surja',
  'rosen',
  'rezos',
  'remos',
  'remar',
  'rapaz',
  'posea',
  'polen',
  'party',
  'mudas',
  'mides',
  'marre',
  'mamut',
  'magma',
  'hueca',
  'halar',
  'habio',
  'guido',
  'gomas',
  'faros',
  'emoto',
  'densa',
  'copos',
  'clama',
  'ciase',
  'acres',
  'yerba',
  'viral',
  'ubica',
  'tapen',
  'sedan',
  'ratos',
  'porto',
  'pedid',
  'nacho',
  'meado',
  'levar',
  'leona',
  'judea',
  'jarro',
  'giren',
  'gamma',
  'fluir',
  'fiero',
  'duden',
  'diran',
  'dingo',
  'cegar',
  'capta',
  'brava',
  'boxer',
  'azote',
  'asoma',
  'alisa',
  'albin',
]
